import { UserClaims } from "@okta/okta-auth-js";
import { MortgageAdvisor, UpdateMaCapsPayload } from "@sindeo/rest-services";
import * as yup from "yup";

export const convertMaCapToPayload = (
  cap: MortgageAdvisor,
  updatedBy: string
): UpdateMaCapsPayload => {
  return {
    ma_caps_id: cap.ma_caps_id as string,
    nmls_id: cap.nmls_id,
    cap_per_day: cap.cap_per_day,
    high_fico_lead_cap_per_day: cap.high_fico_lead_cap_per_day,
    updated_by: updatedBy,
  };
};

export const mortgageAdvisorSchema = yup.object().shape({
  cap_per_day: yup
    .number()
    .min(0, "Cap must be greater than or equal to 0")
    .max(500, "Cap must be less than 500")
    .transform((value) =>
      value && !isNaN(parseInt(value)) ? parseInt(value) : null
    )
    .nullable()
    .typeError("Cap value must be a number"),
});

export const validationSchema = yup.object().shape({
  caps: yup.array().of(mortgageAdvisorSchema),
});

export const setNewState = (
  oldState: UpdateMaCapsPayload[],
  editedCap: MortgageAdvisor,
  userInfo: UserClaims
) => {
  if (editedCap && userInfo?.email) {
    const currentIndex = oldState.findIndex((e) => {
      return e.ma_caps_id === editedCap.ma_caps_id;
    });

    if (currentIndex > -1) {
      oldState[currentIndex] = convertMaCapToPayload(editedCap, userInfo.email);
      return oldState;
    }

    return [...oldState, convertMaCapToPayload(editedCap, userInfo.email)];
  }
  return oldState;
};

export const SAVE_CONFIRMATION_TITLE_MESSAGE =
  "Are you sure you'd like to save your changes to the MA caps?";
export const SAVE_CONFIRMATION_MESSAGE = "Yes";
export const SAVE_CANCELLATION_MESSAGE = "Cancel";
export const CANCEL_CONFIRMATION_TITLE_MESSAGE =
  "Your changes will be lost. Are you sure you want to cancel?";
export const LEAVE_CONFIRMATION_TITLE_MESSAGE =
  "There are unsaved changes. Are you sure you want to return to the Sales Manager Console?";
export const FINISHED_SAVING_TITLE_MESSAGE =
  "Your Daily MA Caps have been updated";
export const FINISHED_SAVING_BACK_MESSAGE = "Back to Sales Manager Console";
export const FINISHED_SAVING_CONTINUE_BUTTON_MESSAGE = "Continue";
