import * as React from "react";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@achieve/sunbeam";
import { yupResolver } from "@hookform/resolvers/yup";
import { UserClaims } from "@okta/okta-auth-js";
import { useOktaAuth } from "@okta/okta-react";
import {
  ConfirmationDialog,
  useConfirmationDialog,
} from "@sindeo/react-components";
import { ControlledInput as Input } from "@sindeo/react-forms";
import { MortgageAdvisor, UpdateMaCapsPayload } from "@sindeo/rest-services";
import { GlobalComponentContext } from "contexts/GlobalComponentContext/GlobalComponentContext";
import { useMortgageAdvisorCaps } from "hooks/useMortgageAdvisorCaps/useMortgageAdvisorCaps";
import { isNil } from "lodash";

import {
  CANCEL_CONFIRMATION_TITLE_MESSAGE,
  FINISHED_SAVING_BACK_MESSAGE,
  FINISHED_SAVING_CONTINUE_BUTTON_MESSAGE,
  FINISHED_SAVING_TITLE_MESSAGE,
  LEAVE_CONFIRMATION_TITLE_MESSAGE,
  SAVE_CANCELLATION_MESSAGE,
  SAVE_CONFIRMATION_MESSAGE,
  SAVE_CONFIRMATION_TITLE_MESSAGE,
  setNewState,
  validationSchema,
} from "./MortgageAdvisorsDailyCapsManager.utils";

const tableColumns = [
  "Mortgage Advisor",
  "Lead cap per day",
  "High FICO lead cap per day",
  "High FICO leads served today",
  "Leads received so far today",
  "Remaining leads that can be received today",
];
const align = "center";

const MortgageAdvisorsDailyCapsManager: React.FC = () => {
  const {
    mortgageAdvisorCaps,
    isMortgageAdvisorCapsLoading,
    updateMortgageAdvisorCaps,
    isUpdateMortgageAdvisorCapsLoading,
  } = useMortgageAdvisorCaps(true);
  const { setShowExitConfirmation, setExitConfirmationMessage } = useContext(
    GlobalComponentContext
  );
  const {
    props: cancellationDialogProps,
    actions: { open: openCancelConfirmationDialog },
  } = useConfirmationDialog({
    closeWhenConfirmed: true,
    isCloseIconShown: false,
    displayStackedActions: true,
  });

  const {
    props: saveDialogProps,
    actions: { open: openSaveConfirmationDialog },
  } = useConfirmationDialog({
    closeWhenConfirmed: true,
    isCloseIconShown: false,
    displayStackedActions: true,
  });

  const {
    props: finishedDialogProps,
    actions: { open: openFinishedConfirmationDialog },
  } = useConfirmationDialog({
    closeWhenConfirmed: true,
    isCloseIconShown: false,
    displayStackedActions: true,
  });

  const [userInfo, setUserInfo] = useState<UserClaims>();
  const [mortgageAdvisorCapsPayloadState, setMortgageAdvisorCapsPayloadState] =
    useState<UpdateMaCapsPayload[]>([]);

  /**
   * "editingIndex" consists of an array, where
   * the first element is the MA index, and the second element is the name of the edit field
   **/
  const [editingIndex, setEditingIndex] = useState<
    null | [number, keyof MortgageAdvisor]
  >(null);

  const { oktaAuth } = useOktaAuth();
  const defaultValues = useMemo(() => {
    return { caps: mortgageAdvisorCaps };
  }, [mortgageAdvisorCaps]);

  const formMethods = useForm({
    defaultValues,
    mode: "onChange",
    shouldUnregister: false,
    resolver: yupResolver(validationSchema),
  });

  const values = formMethods.watch();
  const { reset } = formMethods;

  const handleUpdateMACaps = useCallback(async () => {
    if (mortgageAdvisorCapsPayloadState?.length) {
      setEditingIndex(null);
      const { isConfirmed } = await openSaveConfirmationDialog({
        title: SAVE_CONFIRMATION_TITLE_MESSAGE,
        cancelText: SAVE_CANCELLATION_MESSAGE,
        confirmationText: SAVE_CONFIRMATION_MESSAGE,
      });

      if (isConfirmed) {
        await updateMortgageAdvisorCaps(mortgageAdvisorCapsPayloadState);
        setMortgageAdvisorCapsPayloadState([]);
        const { isConfirmed: isNotFinished } =
          await openFinishedConfirmationDialog({
            title: FINISHED_SAVING_TITLE_MESSAGE,
            cancelText: FINISHED_SAVING_BACK_MESSAGE,
            confirmationText: FINISHED_SAVING_CONTINUE_BUTTON_MESSAGE,
          });
        if (isNotFinished) {
          return;
        }
        return window.location.replace("/");
      }
    }
  }, [
    mortgageAdvisorCapsPayloadState,
    openFinishedConfirmationDialog,
    openSaveConfirmationDialog,
    updateMortgageAdvisorCaps,
  ]);

  const handleCancel = useCallback(async () => {
    const { isConfirmed } = await openCancelConfirmationDialog({
      title: CANCEL_CONFIRMATION_TITLE_MESSAGE,
    });
    if (isConfirmed) {
      reset(defaultValues);
      setEditingIndex(null);
      setMortgageAdvisorCapsPayloadState([]);
    }
  }, [defaultValues, openCancelConfirmationDialog, reset]);

  const hasEditsToSave = useMemo(
    () => mortgageAdvisorCapsPayloadState.length > 0,
    [mortgageAdvisorCapsPayloadState]
  );

  const canSave = useMemo(
    () => hasEditsToSave && formMethods.formState.isValid,
    [formMethods.formState.isValid, hasEditsToSave]
  );

  useEffect(() => {
    setShowExitConfirmation(hasEditsToSave);
    setExitConfirmationMessage(
      hasEditsToSave ? LEAVE_CONFIRMATION_TITLE_MESSAGE : undefined
    );
  }, [hasEditsToSave, setExitConfirmationMessage, setShowExitConfirmation]);

  useEffect(() => {
    if (!isNil(editingIndex)) {
      const editedCap = values?.caps?.[editingIndex[0]];
      if (editedCap && userInfo) {
        const newState = setNewState(
          mortgageAdvisorCapsPayloadState,
          editedCap,
          userInfo
        );
        setMortgageAdvisorCapsPayloadState(newState);
      }
    }
  }, [editingIndex, values, userInfo, mortgageAdvisorCapsPayloadState]);

  useEffect(() => {
    if (!userInfo) {
      oktaAuth?.token?.getUserInfo().then((info) => {
        return setUserInfo(info);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oktaAuth]); // Update if authState changes

  if (isMortgageAdvisorCapsLoading || isUpdateMortgageAdvisorCapsLoading) {
    return (
      <Box sx={{ display: "flex", alignItems: "center", height: "30vh" }}>
        <CircularProgress size={40} />
      </Box>
    );
  }

  return (
    <Paper
      sx={{
        width: "100%",
        overflow: "hidden",
        boxShadow: "none",
      }}
    >
      <FormProvider {...formMethods}>
        <TableContainer sx={{ maxHeight: 440, overflow: "scroll !important" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {tableColumns.map((column) => (
                  <TableCell key={column} align={align}>
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {values?.caps?.map((advisor, index) => {
                return (
                  <TableRow key={advisor.nmls_id}>
                    <TableCell>
                      {advisor.first_name} {advisor.last_name}
                    </TableCell>

                    <TableCell
                      align={align}
                      onClick={() => setEditingIndex([index, "cap_per_day"])}
                      data-testid={`editableCell-${advisor.nmls_id}`}
                    >
                      {editingIndex?.[0] === index &&
                      editingIndex?.[1] === "cap_per_day" ? (
                        <Input
                          InputProps={{ autoFocus: true }}
                          numberFormatProps={{
                            allowNegative: false,
                            onBlur: () => setEditingIndex(null),
                          }}
                          name={`caps[${index}].cap_per_day`}
                          label=""
                          type={"number"}
                          data-testid={`editingCell-${advisor.nmls_id}`}
                        />
                      ) : (
                        <>{advisor.cap_per_day ?? "-"}</>
                      )}
                    </TableCell>

                    <TableCell
                      align={align}
                      data-testid={`editingHighFicoLeadCapacityPerDayCell-${advisor.nmls_id}`}
                      onClick={() =>
                        setEditingIndex([index, "high_fico_lead_cap_per_day"])
                      }
                    >
                      {editingIndex?.[0] === index &&
                      editingIndex?.[1] === "high_fico_lead_cap_per_day" ? (
                        <Input
                          InputProps={{
                            autoFocus: true,
                            onBlur: () => setEditingIndex(null),
                          }}
                          numberFormatProps={{
                            allowNegative: false,
                          }}
                          name={`caps[${index}].high_fico_lead_cap_per_day`}
                          label=""
                          type={"number"}
                          data-testid={`editingHighFicoLeadCapacityPerDayCell-${advisor.nmls_id}`}
                        />
                      ) : (
                        <>{advisor.high_fico_lead_cap_per_day ?? "-"}</>
                      )}
                    </TableCell>
                    <TableCell align={align}>
                      {advisor.high_fico_leads_served_today ?? "-"}
                    </TableCell>
                    <TableCell align={align}>
                      {advisor.leads_served_today ?? "-"}{" "}
                    </TableCell>
                    <TableCell align={align}>
                      {advisor?.cap_per_day &&
                      !isNil(advisor?.leads_served_today)
                        ? advisor?.cap_per_day - advisor?.leads_served_today
                        : "-"}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </FormProvider>
      <Stack alignItems={"center"} sx={{ marginTop: "20px" }}>
        <Button
          sx={{ width: 350, marginTop: "20px" }}
          onClick={handleUpdateMACaps}
          disabled={!canSave}
          data-testid="capsManagerSaveButton"
        >
          Save
        </Button>
        <Button
          variant="outlined"
          sx={{ width: 350, marginTop: "20px" }}
          onClick={handleCancel}
          disabled={!hasEditsToSave}
          data-testid="capsManagerCancelButton"
        >
          Cancel
        </Button>
      </Stack>
      <ConfirmationDialog {...cancellationDialogProps} />
      <ConfirmationDialog {...saveDialogProps} />
      <ConfirmationDialog {...finishedDialogProps} />
    </Paper>
  );
};

export default MortgageAdvisorsDailyCapsManager;
